<template>
    <div v-html="content.code"></div>
</template>

<script>
    export default {
        name: "BlockHtml",
        props: {
            content: {}
        }
    }
</script>

<style scoped>

</style>